






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventAttendee } from '../../models/event-attendee.model';
import { EventDetails } from '../../models/event-details.model';

@Component
export default class AttendeeRegistration extends Vue {
  @Prop()
  eventDetails!: EventDetails;
  @Prop()
  attendee!: EventAttendee;

  guestCount = this.attendee.guestCount ?? 0;

  subscribe(): void {
    this.$emit('subscribe', {
      memberId: this.attendee.id,
      guestCount: this.guestCount
    })
  }

  reject(): void {
    this.$emit('reject', {
      memberId: this.attendee.id
    })
  }

  retry(): void {
    this.$emit('retry', {
      memberId: this.attendee.id
    })
  }

  get totalPrice(): number {
    return this.eventDetails!.priceNormal + this.guestCount * this.eventDetails.priceGuest;
  }
  get guestsOptions(): { value: number, text: number}[] {
    const options = []
    for (let i = 0; i <= this.eventDetails!.maxGuestsPerSubscription; i++) {
      options.push({ value: i, text: i })
    }
    return options;
  }
}
