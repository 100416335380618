import { render, staticRenderFns } from "./EventDetails.vue?vue&type=template&id=ae6bac10&"
import script from "./EventDetails.vue?vue&type=script&lang=ts&"
export * from "./EventDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/working_dir/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ae6bac10')) {
      api.createRecord('ae6bac10', component.options)
    } else {
      api.reload('ae6bac10', component.options)
    }
    module.hot.accept("./EventDetails.vue?vue&type=template&id=ae6bac10&", function () {
      api.rerender('ae6bac10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/events/pages/EventDetails.vue"
export default component.exports