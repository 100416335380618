var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", [
        _c("h5", { staticClass: "col-12 mt-3 mb-4" }, [
          _vm._v(_vm._s(_vm.currentEventDetails.title)),
        ]),
        _c(
          "div",
          { staticClass: "col mb-5" },
          [
            _c("div", { staticClass: "mb-4" }, [
              _vm.currentEventDetails.imageUrl
                ? _c("img", {
                    staticClass: "mb-3 logo",
                    attrs: {
                      src: _vm.currentEventDetails.imageUurl,
                      alt: "Event logo",
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-10" }, [
                  _vm._v(" " + _vm._s(_vm.currentEventDetails.location)),
                  _vm.currentEventDetails.subLocation
                    ? _c("span", [
                        _vm._v(
                          ", " + _vm._s(_vm.currentEventDetails.subLocation)
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-10" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getStartDate(_vm.currentEventDetails.startDate)
                      ) +
                      " - " +
                      _vm._s(
                        _vm.getEndDate(
                          _vm.currentEventDetails.endDate,
                          _vm.currentEventDetails.startDate
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.currentEventDetails.description),
              },
            }),
            _vm.currentEventDetails.priceNormal
              ? _c("div", { staticClass: "row" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _vm._v(
                      " € " + _vm._s(_vm.currentEventDetails.priceNormal) + " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.currentEventDetails.allowGuests &&
            _vm.currentEventDetails.priceGuest
              ? _c("div", { staticClass: "row" }, [
                  _vm._m(3),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _vm._v(
                      " € " + _vm._s(_vm.currentEventDetails.priceGuest) + " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._l(
              _vm.currentEventDetails.possibleAttendees,
              function (attendee) {
                return _c("AttendeeRegistration", {
                  key: attendee.id,
                  attrs: {
                    eventDetails: _vm.currentEventDetails,
                    attendee: attendee,
                  },
                  on: {
                    subscribe: _vm.subscribeEvent,
                    reject: _vm.rejectEvent,
                    retry: _vm.retryPayment,
                  },
                })
              }
            ),
            _c("div"),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-12 mb-4" },
          [
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFromHome,
                    expression: "isFromHome",
                  },
                ],
                attrs: { to: "/" },
              },
              [
                _c("span", { staticClass: "badge badge-dark badge-pill" }, [
                  _c("i", { staticClass: "fa fa-angle-left" }),
                ]),
                _vm._v(" Terug naar de lijst"),
              ]
            ),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFromHome,
                    expression: "!isFromHome",
                  },
                ],
                attrs: { to: "/events" },
              },
              [
                _c("span", { staticClass: "badge badge-dark badge-pill" }, [
                  _c("i", { staticClass: "fa fa-angle-left" }),
                ]),
                _vm._v(" Terug naar de lijst"),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("strong", [_c("label", [_vm._v("Waar?")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("strong", [_c("label", [_vm._v("Wanneer?")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("strong", [_c("label", [_vm._v("Bedrag per lid")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-2" }, [
      _c("strong", [_c("label", [_vm._v("Bedrag per gast")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }