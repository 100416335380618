var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-2" }, [
    _c("div", { staticClass: "col-lg-3" }, [
      _vm._v(
        " " +
          _vm._s(_vm.attendee.firstName) +
          ", " +
          _vm._s(_vm.$t("events.attendeeRegistrations.title")) +
          "? "
      ),
    ]),
    !_vm.attendee.status
      ? _c("div", { staticClass: "col-lg-9" }, [
          _c("div", { staticClass: "row" }, [
            _vm.eventDetails.allowGuests
              ? _c("div", { staticClass: "col-lg-3" }, [
                  _vm.eventDetails.allowGuests
                    ? _c(
                        "span",
                        { staticClass: "mr-2" },
                        [
                          _c("b-form-select", {
                            staticStyle: { width: "50%" },
                            attrs: { options: _vm.guestsOptions },
                            model: {
                              value: _vm.guestsCount,
                              callback: function ($$v) {
                                _vm.guestsCount = $$v
                              },
                              expression: "guestsCount",
                            },
                          }),
                          _vm._v(" tickets"),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col-lg-9" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success mr-2",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.subscribe.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.eventDetails.priceNormal || _vm.eventDetails.priceGuest
                    ? _c("span", [_vm._v("€ " + _vm._s(_vm.totalPrice))])
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("events.attendeeRegistrations.register"))
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.reject.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Nee, Ik ben niet aanwezig")]
              ),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "col-lg-9" }, [
          _vm.attendee.status === "rejected"
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v("Ik ben niet aanwezig"),
              ])
            : _vm.attendee.status === "completed" ||
              _vm.attendee.status === "paid"
            ? _c("span", { staticClass: "text-success" }, [
                _vm._v(" Ik ben aanwezig "),
                _vm.attendee.guestCount > 1
                  ? _c("span", [
                      _vm._v(
                        "met " + _vm._s(_vm.attendee.guestCount) + " gasten"
                      ),
                    ])
                  : _vm.attendee.guestCount > 0
                  ? _c("span", [_vm._v("met 1 gast")])
                  : _vm._e(),
              ])
            : _vm.attendee.status === "awaitingPayment"
            ? _c("span", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success mr-2",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.retry.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.eventDetails.priceNormal || _vm.eventDetails.priceGuest
                      ? _c("span", [_vm._v("€ " + _vm._s(_vm.totalPrice))])
                      : _vm._e(),
                    _vm._v(" Probeer de betaling opnieuw"),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }