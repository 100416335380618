































































import { Action, Getter } from 'vuex-class';
import { Component,  Vue } from 'vue-property-decorator';
import { EventDetails as EventDetailsModel } from '../models/event-details.model';
import AttendeeRegistration from './components/AttendeeRegistration.vue';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import dayjs from 'dayjs';

@Component({
  components: {
    AttendeeRegistration
  }
})
export default class EventDetails extends Vue {
  isFromHome = false;
  registrations = [];

  @Action
  setLoading!: (loading: boolean) => Promise<void>;
  @Action
  loadEventDetails!: (eventId: string) => Promise<void>;
  @Action
  eventsSubscribe!: (payload: {eventId: string, memberId: string, guestsCount: number, isPaidEvent: boolean}) => Promise<void>
  @Action
  eventsReject!: (payload: {eventId: string, memberId: string}) => Promise<void>
  @Action
  eventsPayment!: (payload: {eventId: string, memberId: string}) => Promise<void>

  @Getter
  eventDetails!: (eventId: string) => EventDetailsModel | null;

  async mounted() {
    await this.loadDetails();
    this.isFromHome = this.$route.params.source == 'home';
  }

  async loadDetails() {
    await this.setLoading(true);
    await this.loadEventDetails(this.eventId);
    await this.setLoading(false);
  }

  getStartDate(date: string): string {
    return dayjs(date).format('D MMM HH:mm');
  }

  getEndDate(date: string, dateToCheckIfNotSameDay: string): string {
    const parsedDateToCheckIfNotSameDay = dayjs(dateToCheckIfNotSameDay)
    const parsedDate = dayjs(date)

    if (parsedDate.date() === parsedDateToCheckIfNotSameDay.date()) {
      return parsedDate.format('HH:mm')
    } else {
      return parsedDate.format('D MMM HH:mm')
    }
  }
  
  async subscribeEvent(payload: any): Promise<void> {
    try {
      await this.eventsSubscribe({
        eventId: this.currentEventDetails!.id,
        memberId: payload.memberId,
        guestsCount: payload.guestsCount,
        isPaidEvent: !!this.currentEventDetails!.priceNormal
      })
      await this.loadDetails();
    } catch {
      ToastHelper.showError(this.$bvToast, 'Er is een fout opgetreden bij het verwerken van uw verzoek');
    }
  }

  async rejectEvent(payload: any): Promise<void> {
    try {
      await this.eventsReject({
        eventId: this.currentEventDetails!.id,
        memberId: payload.memberId
      })
      await this.loadDetails();
    } catch {
      ToastHelper.showSuccess(this.$bvToast, 'Er is een fout opgetreden bij het verwerken van uw verzoek');
    }
  }

  async retryPayment(payload: any): Promise<void> {
    try {
      await this.eventsPayment({
        eventId: this.currentEventDetails!.id,
        memberId: payload.memberId
      })
      await this.loadDetails();
    } catch {
      ToastHelper.showError(this.$bvToast, 'Er is een fout opgetreden bij het verwerken van uw verzoek');
    }
  }

  get guestsOptions() {
    const options = []
    for (let i = 0; i <= this.currentEventDetails!.maxGuestsPerSubscription; i++) {
      options.push({ value: i, text: i })
    }
    return options;
  }

  get currentEventDetails(): EventDetailsModel | null {
    return this.eventDetails(this.eventId);
  }

  get isLoading(): boolean {
    return !this.currentEventDetails;
  }

  get eventId(): string {
    return this.$route.params.eventId;
  }
}
